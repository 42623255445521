




















import Agreement from './data_usage_agreement_component'

DATA_MIXIN = {
  data: ->
    backUrl: ''
    message: ''
    timestamp: ''
    existsTimestamp: ''
    fields: []
    savePath: ''
    revokePath: ''
    mode: 'preview'
    agreementExists: false
}

export default {
  mixins: [DATA_MIXIN]
  components: {Agreement}

  computed: 
    bindProps: ->
      message: @message
      timestamp: @timestamp
      fields: @fields
      existsTimestamp: @existsTimestamp
  methods:

    revoke: ->
      vm = @
      $.ajax({
        url: @revokePath,
        data: @bindProps,
        method: 'POST',
        success: (data)->
          if data.message
            vm.toast(data.success, data.message)
          if data.success
            vm.existsTimestamp = ''
            vm.agreementExists = false
      })

    navBack: ->
      if @backUrl == 'back'
        history.back()
      else if @backUrl != ''
        window.location = @backUrl

    toast: (success, message)->
      icon = 'check'
      method = 'success'
      if !success
        icon = 'error'
        method = 'error'

      @$toasted[method](message, {
        theme: "outline"
        position: "bottom-left"
        icon : icon
        duration : 3000
        action : {
          icon : 'clear',
          onClick : (e, toastObject) ->
              toastObject.goAway(0);
        }
      })

    submitForm: ->
      vm = @
      $.ajax({
        url: @savePath,
        data: @bindProps,
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({ agreement: @fields }),
        success: (data) ->
          if data.message
            vm.toast(data.success, data.message)
          if data.success
            vm.agreementExists = true
            vm.existsTimestamp = data.exists_timestamp
      })

}
