










































































import axios from "axios"

DATA_MIXIN = {
  data: ->
    currentPage: 0
    currentPageNum: 0
    resultContent: ''
    perPage: 0
    searchUrl: ''
    jsonSearchUrl: ''
    pagesCount: 0
    resultCount: 0
    viewingItems: ''
    orderTypes: []
    order: ''
    disableParams: false
    scopeTypes: []
    contentTypes: []
    needChangeFilter: false
    needUpdatePageParam: false
}

export default {
  name: 'SearchResultView'

  mixins: [DATA_MIXIN]

  computed:
    pageContent: ->
      @resultContent
    pagesCountNum: ->
      parseInt(@pagesCount)
  mounted: ->
    num = parseInt(@currentPage.toString())
    num = 1 if num < 1
    @currentPageNum = num
    @resultCount = parseInt(@resultCount)
  watch:
    currentPageNum: (new_value, _)->
      pp = parseInt(@perPage)
      from = (new_value - 1) * pp + 1
      to = new_value * pp
      to = @resultCount if to > @resultCount
      @viewingItems = from + '-' + to
    order: ->
      @onParamsChanged()
    contentTypes: ->
      @needUpdatePageParam = true
      @onParamsChanged()

  methods:
    pageChangedHandler: ->
      @needUpdatePageParam = false
      @onParamsChanged()
    updatePageContent: ->
      @setParamsState(false)

      historyUrl = @searchUrl
      ajaxUrl = @jsonSearchUrl
      paramSymbol = if ajaxUrl?.includes('?') then '&' else '?'

      if @order != 'default'
        historyUrl += "#{paramSymbol}order=" + @order
        ajaxUrl += "#{paramSymbol}order=" + @order
        paramSymbol = '&'

      if @contentTypes.length != Object.keys(@scopeTypes).length
        historyUrl += "#{paramSymbol}content_types=" + @contentTypes.join(',')
        ajaxUrl += "#{paramSymbol}content_types=" + @contentTypes.join(',')
        paramSymbol = '&'

      if @currentPageNum > 1 || @needUpdatePageParam
        @currentPageNum = 1 if @needUpdatePageParam
        historyUrl += "#{paramSymbol}page=" + @currentPageNum
        ajaxUrl += "#{paramSymbol}page=" + @currentPageNum
        paramSymbol = '&'

      history.pushState(null, null, historyUrl)
      $.ajax({
        url: ajaxUrl,
        dataType: 'json',
        success: @onPageLoaded,
        error: @onPageLoadError
      })

    onPageLoaded: (data)->
      if document.documentElement.scrollTop > 300
        vm = @
        $('html, body').stop().animate({scrollTop:60}, 500, 'swing', ->
          vm.setParamsState(true)
        )
      else
        @setParamsState(true)
      @needUpdatePageParam = false
      if @resultCount != data.resultCount && !!data.resultCount
        @currentPageNum = 1
        @needUpdatePageParam = true
      @resultContent = data.resultContent
      @needChangeFilter = data.resultCount == 0
      @resultCount = data.resultCount if data.resultCount
      @pagesCount = data.pagesCount if data.pagesCount

    setParamsState: (enable)->
      @disableParams = !enable

    onParamsChanged: ->
      @$nextTick ->
        @updatePageContent()

    onPageLoadError: (xhr, status, message) ->
      alert('Ошибка запроса результатов: '+ message)
      @setParamsState(true)

    scopeTypeTitle: (scopeType) ->
      @$i18n("pages.search.scope_content_types.#{@scope}.#{scopeType}")

    needChangeFilterText: (name) ->
      @$i18n("search_page.#{name}")
}
