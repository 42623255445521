


























































import Agreement from './data_usage_agreement_component'

DATA_MIXIN = {
  data: ->
    previewMessage: ''
    message: ''
    timestamp: ''
    fields: []
    existsTimestamp: ''
    savePath: ''
    revokePath: ''
    mode: 'preview'
    fullAgreementCallback: null
}

export default {
  mixins: [DATA_MIXIN]
  components: {Agreement}

  computed: 
    bindProps: ->
      message: @message
      timestamp: @timestamp
      fields: @fields
      existsTimestamp: @existsTimestamp

  methods:
    submitForm: ->
      vm = @
      $.ajax({
        url: @savePath,
        data: @bindProps,
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({ agreement: @fields }),
        success: (data) ->
          if data.message
            vm.toast(data.success, data.message)

          if data.full_agreement
            if vm.fullAgreementCallback
              vm.fullAgreementCallback()

          $(vm.$el).find('.close-button').click()
      })

    toast: (success, message)->
      icon = 'check'
      method = 'success'
      if !success
        icon = 'error'
        method = 'error'

      @$toasted[method](message, {
        theme: "outline"
        position: "bottom-left"
        icon : icon
        duration : 3000
        action : {
          icon : 'clear',
          onClick : (e, toastObject) ->
              toastObject.goAway(0);
        }
      })

}
