









































































































# DATA_MIXIN = {
#   data: ->
#     timestamp: ''
#     fields: []
#     savePath: ''
#     revokePath: ''
# }

export default {
  # mixins: [DATA_MIXIN]

  props:
    message: String
    timestamp: String
    fields: Array
    savePath: String
    revokePath: String
    existsTimestamp: String

  computed:
    canSave: ->
      !!@savePath

    canRevoke: ->
      !!@revokePath

    etsText: ->
      @$I18n('user_pda.existsTimestamp') + ' ' + @existsTimestamp

  methods:

    revoke: ->
      $.ajax(
          url: @revokePath
          method: 'POST'
      )

    save: ->
      data = {

      }

      $.ajax(
          url: @savePath
          method: 'POST'
          dataType: 'json'
          data: JSON.stringify({user_agreement: @fields})
          contentType: 'application/json'
          success: (data)->
      )
}
